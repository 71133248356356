<template>
  <div class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>AIOT</el-breadcrumb-item>
           <el-breadcrumb-item>家居设备</el-breadcrumb-item>
           <el-breadcrumb-item>场景配置</el-breadcrumb-item>
           <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>

    <el-row>
      <el-link type="primary" class="back_iconfont" icon="iconfont iconfanhui1" @click="goBack()">
        <span v-text="sceneData.sceneName"></span>
      </el-link>
    </el-row>
    <!-- 详细信息 -->
    <div class="det-box">
      <el-divider content-position="left"><span v-text="$t('msg.scene_info')">场景信息</span></el-divider>
      <ul>
         <li>
            <span>会员号：</span>
            <span v-text="userName">—</span>
         </li>
        <li>
          <span v-text="$t('msg.unlock_status_a')">是否锁定：</span>
          <span v-if="sceneData.locked === 'LOCKED'" v-text="$t('msg.are_unlocked')" style="color: #67c23a;">已锁定</span>
          <span v-else v-text="$t('msg.not_unlock')" style="color: #F56C6C;">未锁定</span>
        </li>
        <li>
          <span v-text="$t('msg.remarks_a')">备注信息：</span>
          <span v-text="sceneData.remark">—</span>
        </li>
      </ul>
      <ul>
        <li>
          <span v-text="$t('msg.scene_off_time_a')">场景关闭时间：</span>
          <span>{{closingTime}}秒</span>
        </li>
        <li>
          <span v-text="$t('msg.sync_gateway_a')">同步网关状态：</span>
          <span v-if="sceneData.syncGatewayState === 'UN_SYNC'" style="color: #9797A2;" v-text="$t('msg.no_sync')">未同步</span>
          <span v-else-if="sceneData.syncGatewayState === 'ADD_REPLY'" style="color: #e6a23c;" v-text="$t('msg.add_gateway')">新增中 - 待网关回复</span>
          <span v-else-if="sceneData.syncGatewayState === 'UPDATED_UN_SYNC'" style="color: #9797A2;" v-text="$t('msg.edit_sync')">已修改 - 未同步</span>
          <span v-else-if="sceneData.syncGatewayState === 'UPDATE_REPLY'" style="color: #e6a23c;" v-text="$t('msg.edit_gateway')">修改中 - 待网关回复</span>
          <span v-else-if="sceneData.syncGatewayState === 'DELETE_REPLY'" style="color: #e6a23c;" v-text="$t('msg.del_gateway')">删除中 - 待网关回复</span>
          <span v-else-if="sceneData.syncGatewayState === 'SUCCESS'" style="color: #67c23a;" v-text="$t('msg.sync_success')">同步成功</span>
          <span v-else-if="sceneData.syncGatewayState === 'FAILURE'" style="color: #F56C6C;" v-text="$t('msg.sync_failure')">同步失败</span>
        </li>
      </ul>
      <ul>
        <li>
          <span v-text="$t('msg.scene_off_delayed_a')">场景延时时间：</span>
          <span>{{delayTime}}秒</span>
        </li>
        <li>
          <span v-text="$t('msg.create_time_a')">创建时间：</span>
          <span v-text="sceneData.createTime">—</span>
        </li>
      </ul>
    </div>
    <!-- 场景同步终端 -->
    <div class="det-box">
      <el-divider class="m-bottom-20" content-position="left"><span v-text="$t('msg.sence_sync')">场景同步终端</span></el-divider>
      <el-row class="m-bottom-15">
        <span v-for="(item, idx) in sceneData.sceneSyncList" :key="idx" class="icon-list">
          <i class="el-icon-monitor"></i>
          <span class="p-left-5" v-if="item.deviceType === 'WECHAT'" v-text="$t('msg.small_program')">小程序</span>
          <span class="p-left-5" v-if="item.deviceType === 'VOICE'" v-text="$t('msg.intelligent_voice')">智能语音</span>
        </span>
      </el-row>
    </div>
    <!-- 触发设备列表 -->
    <div class="det-box">
      <el-divider content-position="left"><span v-text="$t('msg.trigger_dev_list')">触发设备列表</span></el-divider>
      <el-table
              :data="sceneData.triggerDeviceList"
              style="width: 100%"
              min-height="780"
              max-height="780"
              :stripe="true"
              >
        <el-table-column prop="deviceName" :label="$t('msg.dev_number')"></el-table-column>
        <el-table-column prop="versionsName" :label="$t('msg.dev_name')"></el-table-column>
        <el-table-column prop="alias" :label="$t('msg.alias')"></el-table-column>
        <el-table-column prop="location" :label="$t('msg.location')"></el-table-column>
        <el-table-column prop="deviceAction" :label="$t('msg.status')">
          <template slot-scope="scope">
            <span v-if="scope.row.deviceAction === 'TURNON'" v-text="$t('msg.open_s')">开</span>
            <span v-if="scope.row.deviceAction === 'TURNOFF'" v-text="$t('msg.close_s')">关</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 被触发设备列表 -->
    <div class="det-box">
      <el-divider content-position="left"><span v-text="$t('msg.oftrigger_dev_list')">被触发设备列表</span></el-divider>
      <el-table
              :data="sceneData.targetDeviceList"
              style="width: 100%"
              min-height="780"
              max-height="780"
              :stripe="true"
              >
        <el-table-column prop="deviceName" :label="$t('msg.dev_number')"></el-table-column>
        <el-table-column prop="versionsName" :label="$t('msg.dev_name')"></el-table-column>
        <el-table-column prop="alias" :label="$t('msg.alias')"></el-table-column>
        <el-table-column prop="location" :label="$t('msg.location')"></el-table-column>
        <el-table-column prop="deviceAction" :label="$t('msg.status')">
          <template slot-scope="scope">
            <span v-if="scope.row.deviceAction === 'TURNON'" v-text="$t('msg.open_s')">开</span>
            <span v-if="scope.row.deviceAction === 'TURNOFF'" v-text="$t('msg.close_s')">关</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
   import { urlObj } from '@/api/interface'
   export default {
      data () {
         return {
            sceneData: {},    // 场景详情
            userName: '',     // 会员号
            closingTime: '',  // 关闭时间
            delayTime: '',    // 延迟时间
            triggerData: [], // 触发设备
            targetData: [], // 被触发设备

         }
      },
      mounted () {
         this.userName = this.$route.query.userName
         this.getSceneDetail()
      },
      methods: {
         // 获取设备详情
         getSceneDetail () {
            const sceneId = this.$route.query.id
            const url = urlObj.homeSceneDetail
            const param = {
               sceneId,
            }
            this.$axios.get(url, param).then(res => {
               if (res.success) {
                  let sceneData = res.records
                  this.closingTime = sceneData.delayExecuteTime.split('|')[0]
                  this.delayTime = sceneData.delayExecuteTime.split('|')[1]
                  this.sceneData = sceneData
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 返回上页
         goBack(){
            this.$router.push('/home_scenes_config')
         },
      }
   }
</script>
<style scoped lang="scss">
  .cont{
    min-width: 1120px;
    .box-span{
      vertical-align: middle;
      margin-left: 10px;
    }
  }
  .el-row{
    line-height: 30px;
    white-space: nowrap;
  }
  .det-box{
    /* border: 1px solid red; */
    background: white;
    padding: 10px 20px;
    margin-top: 10px;
    &::after{
      content: "";
      display: block;
      clear: both;
    }
    &:nth-child(2){
      margin-top: 0;
    }
    ul{
      width: 25%;
      float: left;
      list-style: none;
      li{
      //   float: left;
      // width: 25%;
        position: relative;
        height: 30px;
        line-height: 30px;
        margin-bottom: 20px;
        font-size: 14px;
        white-space: nowrap;
        .box-img{
          position: absolute;
          top: 0;
          left: 0;
          width: 60%;
          height: 130px;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .icon-list{
      width: 15%;
      display: inline-block;
    }
    .box-pad{
      color: #00BFBF;
      border: 1px solid rgba(0, 191, 191, 1);
      background: rgba(0, 191, 191, 0.2);
      padding: 5px;
    }
  }
</style>
